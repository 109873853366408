<template>
  <div>
    <div class="content">
      <div class="contentL">
        <div class="contentLtitle">组织结构</div>
        <el-input class="inputValue" v-model="inputValue" placeholder="请输入关键词搜索" @click="handleSearch"
          suffix-icon="el-icon-search">
        </el-input>
        <div class="tree-container">
          <el-tree ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData" :check-on-click-node="false
            " :props="defaultProps" node-key="id" @node-click="handleCheck"></el-tree>
        </div>

      </div>
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">变压器</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="newView">
              <!-- <el-button type="primary" @click="openViewDialog" icon="el-icon-plus" class="custom-button">新建公告</el-button> -->

              <div class="day" v-for="(item, index) in dayList" :key="index" :class="day == index ? 'day1' : ''"
                @click="dayFn(index)">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="searchContent">

          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="日期：">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input"
                v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-arrow-left" class="custom-button">上一日</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-arrow-right" class="custom-button">下一日</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-download" class="custom-button">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="searchContent">
          <div class="titleEcharts">{{ checkedNodes.label }}设备能耗</div>
        </div>
        <div class="searchContent">
          <div id="myEcharts" class="mycharts">
          </div>
        </div>
        <div class="table">
          <el-table border :data="tableData" style="width: 100%" class="custom-table">
            <el-table-column align="center" label="序号" type="index" width="200">
            </el-table-column>
            <el-table-column align="center" prop="name" label="设备名称" :fluid="true">
            </el-table-column>
            <el-table-column align="center" prop="number" label="设备编号" width="400">
            </el-table-column>
            <el-table-column align="center" prop="consumption" label="设备能耗" width="400">
            </el-table-column>
            <el-table-column align="center" prop="time" label="时间" width="400">
            </el-table-column>

          </el-table>
        </div>

      </div>
    </div>



  </div>
</template>

<script>
import { boardList } from '../../api/dailyWork.js'

import * as echarts from "echarts";
export default {
  name: 'Administration',
  data() {
    return {
      formInline: {
        title: '',//标题
        content: '',//内容
        createBy: '',//创建人
        updateTime: '',//创建时间
      },
      tableData: [
        {
          name: '设备名称',
          number: '编号',
          consumption: '能耗情况',
          time: '2022-11-13 15:54:23',
        },
        {
          name: '设备名称',
          number: '编号',
          consumption: '能耗情况',
          time: '2022-11-13 15:54:23',
        },
        {
          name: '设备名称',
          number: '编号',
          consumption: '能耗情况',
          time: '2022-11-13 15:54:23',
        },
        {
          name: '设备名称',
          number: '编号',
          consumption: '能耗情况',
          time: '2022-11-13 15:54:23',
        },
        {
          name: '设备名称',
          number: '编号',
          consumption: '能耗情况',
          time: '2022-11-13 15:54:23',
        },

      ],


      textarea: '',

      treeData: [
        {
          id: 1,
          label: '分中心名称1',
          children: [
            {
              id: 11,
              label: '场站名称1',
              children: [
                { id: 111, label: '配电室' },
                { id: 112, label: '箱变' },
                { id: 113, label: '柱变' },
                {
                  id: 114, label: '强电间',
                  children: [
                    { id: 1141, label: '高压侧' },
                    { id: 1142, label: '低压侧' },
                    { id: 1143, label: '变压器' },
                    { id: 1144, label: '直流屏' },
                  ]
                },
              ],
            },
            {
              id: 12,
              label: '场站名称2',
              children: [
                { id: 121, label: '配电室' },
                { id: 122, label: '箱变' },
                { id: 123, label: '柱变' },
                { id: 124, label: '强电间', }

              ]

            },
          ],
        },
        {
          id: 2,
          label: '分中心名称2',
          children: [
            {
              id: 21,
              label: '场站名称1',
              children: [
                { id: 211, label: '配电室' },
                { id: 212, label: '箱变' },
                { id: 213, label: '柱变' },
                { id: 214, label: '强电间', }

              ]
            },
            {
              id: 22,
              label: '场站名称2',
              children: [
                { id: 221, label: '配电室' },
                { id: 222, label: '箱变' },
                { id: 223, label: '柱变' },
                { id: 224, label: '强电间', }

              ]
            },
          ],
        },
      ],
      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      defaultProps: {
        children: 'children',
        id: 'id',
        label: 'label'
      },
      dayList: ['日', '月', '年'],
      day: 0,

    };
  },
  // components:{demo},

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    // this.getList()
    this.drawEnergy()

  },
  computed: {

  },


  methods: {
    dayFn(el) { this.day = el },
    // tree搜索
    handleSearch() {

    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    //列表查询
    getList() {
      let obj = {
        ...this.formInline,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      boardList(obj).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    //查看
    replyDialog(el) {
      console.log(el.data);
      this.centerDialogVisible = true
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
    },

    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    },
    drawEnergy() {
      var myEcharts = echarts.init(document.getElementById("myEcharts"));
      var option = {
        grid: {
          left: '1%',
          right: '1%',
          containLabel: true
        },
        tooltip: {},
        legend: {
          data: ["能耗情况"],
          right: '31px',
          top: '16px',
          textStyle: {
            color: "#AED6FF"
          }
        },
        backgroundColor: '#071838',
        xAxis: {
          data: [
            "00:01",
            "00:02",
            "00:03",
            "00:04",
            "00:05",
            "00:06",
            "00:07",
            "00:08",
            "00:09",
            "00:10",
            "00:11",
            "00:12",
            "00:13",
            "00:14",
            "00:15",
            "00:16",
            "00:17",
            "00:18",
            "00:19",
            "00:20",
            "00:21",
            "00:22",
            "00:23",
            "00:24",
          ],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: '#aed6ff' // 设置 x 轴标签字体颜色
            }
          }

        },
        yAxis: {
          name: "单位：kWh",
          nameTextStyle: {
            color: '#aed6ff' 
        },
          splitLine: {
            lineStyle: {
              type: 'dashed',//
              color: "#0b2159"
            },
          },
          axisLabel: {
            textStyle: {
                color: '#aed6ff' // 
            }
        }
        },
        series: [
          {
            name: "能耗情况",
            type: "bar",

            data: [
              5, 20, 36, 10, 10, 20, 5, 20, 36, 10, 10, 20, 5, 20, 36, 10, 10,
              20, 5, 20, 36, 10, 10, 20,
            ],
            barWidth: '30%', // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: '#176AD2'
            }
          },
        ],
      };

      myEcharts.setOption(option);
    }


  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/routerImg.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  display: flex;

}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  background-color: #002C6C;
}

.day1 {
  background-color: #002C6C;

}

.mycharts {
  width: 100%;
  height: 500px;
}

.titleEcharts {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}
::v-deep.el-tree{
  width: 300px;
} 

</style>